import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  BusinessHeader,
  ChannelHeader,
  DateHeader,
  IdHeader,
  TextHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Process } from '@/entities/settings'
import { Lead } from '@/entities/crm'
import { CellType } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 40,
  },
  { ...BusinessHeader, text: 'Negocio ', value: 'negotiation.inspection.appraisal.deal.lead.pipeline' },
  {
    ...TextHeader,
    text: 'Cliente',
    value: 'negotiation.client.name',
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto', width: 250 },
  { ...DateHeader, text: 'Fecha de compra ', value: 'created' },
  { ...AppraisalStatusHeader, text: 'Estado de compra', value: '@', width: 150 },
  {
    align: 'center',
    width: 100,
    filterable: true,
    type: CellType.linked,
    text: 'Ejecutivo',
    value: 'negotiation.executive',
    options: {
      action: {
        color: 'green',
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...TextHeader, text: 'Precio autorizado', value: 'authorizePrice', width: 120 },
  { ...TextHeader, text: 'Precio de compra', value: 'amount', width: 120 },
  { ...TextHeader, text: 'Saldo a favor', value: 'marginPositive', width: 120 },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 120 },
]

export default function (): ResourceHeaders {
  return headers
}
