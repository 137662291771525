import { ResourceHeaders } from '..'
import { IdHeader } from '../../headers'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
]

export default function (): ResourceHeaders {
  return headers
}
