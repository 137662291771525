import { ResourceHeaders } from '..'
import {
  ActionHeader, AlertsHeader,
  IdHeader, MarketPricesHeader, PhotoHeader, TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { TableCellHeader } from '@/components/dataTables/cell'
import { extendsObj } from '@/utils/vuetify/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    width: 70,
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...AlertsHeader, text: 'Alerta', value: 'stockPriceAlert', sortable: true },
  { ...PhotoHeader, text: 'Foto', value: '@' },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: '@',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...item }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado stock', value: 'stockStatus' },
  {
    ...MarketPricesHeader,
    text: 'Precio y descuento',
    value: 'priceDiscount',
    width: 240,
  },
  { ...TextHeader, text: 'Llave', value: 'keyIdentifier.keyId', width: 120 },
  { ...TextHeader, text: 'Kilometraje', value: 'kms' },
  { ...TextHeader, text: 'Dueños', value: 'owners' },
  {
    ...ActionHeader,
    text: 'Chileautos',
    value: 'chileautos',
    options: {
      disabled: true,
      action: {
        color: 'blue',
        icon: 'mdi-web',
        only: true,
        trigger: (item: string) => Helpers.portal(item),
      },
    },
  },
  {
    ...ActionHeader,
    text: 'Mercadolibre',
    value: 'mercadolibre',
    options: {
      disabled: true,
      action: {
        color: 'blue',
        icon: 'mdi-web',
        only: true,
        trigger: (item: string) => Helpers.portal(item),
      },
    },
  },
  {
    ...ActionHeader,
    text: 'Yapo',
    value: 'yapo',
    options: {
      disabled: true,
      action: {
        color: 'blue',
        only: true,
        icon: 'mdi-web',
        trigger: (item: string) => Helpers.portal(item),
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
