import { ResourceHeaders } from '..'
import { AppraisalStatusHeader, BackupPaymentHeader, IdHeader, TextHeader, VehicleHeader } from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'
import { CellType } from '@/components/dataTables/cell'
import { Expense, Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  { ...TextHeader, text: 'Tipo de gasto', value: 'expenseType', width: 120 },
  { ...TextHeader, text: 'Beneficiario', value: 'paymentRecipient.person.shortName', width: 120 },
  { ...VehicleHeader, text: 'Vehículo', value: 'deal.auto' },
  {
    align: 'center',
    filterable: true,
    type: CellType.linked,
    text: 'Ejecutivo',
    value: 'deal.lead.executive',
    sortable: false,
    width: 140,
    options: {
      action: {
        color: 'green',
        always: true,
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Person) => Helpers.contact(item, 'executive'),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  {
    align: 'center',
    filterable: true,
    type: CellType.linked,
    text: 'Validador',
    value: 'validator',
    width: 200,
    sortable: false,
    options: {
      path: 'name',
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'blue',
        icon: 'mdi-whatsapp',
        trigger: (item: Expense, process: Process, system: string) => Helpers.contact(item, 'validator', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...TextHeader, text: 'Monto solicitado', value: 'amountFixed', width: 120 },
  { ...TextHeader, text: 'Tipo de pago', value: 'type.description', width: 120 },
  { ...TextHeader, text: 'Cuenta', value: 'accountInfo', width: 200 },
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
  { ...TextHeader, text: 'Responsable', value: 'validator.person.shortName', width: 120 },
  {
    ...BackupPaymentHeader,
    text: 'Respaldo',
    value: '@',
    width: 60,
    sortable: false,
    options: {
      name: 'expense_payment',
    },
  },

]

export default function (): ResourceHeaders {
  return headers
}
