import { ResourceHeaders } from '..'
import {
  AppraisalHeader,
  AppraisalStatusHeader,
  BusinessHeader,
  ChannelHeader,
  DateHeader,
  IdHeader,
  PdfPurchaseHeader,
  PdfResponsabilityHeader,
  PersonHeader,
  PriceHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...BusinessHeader, text: 'Negocio ', value: 'negotiation.inspection.appraisal.deal.lead.pipeline' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'negotiation.client.name',
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto' },
  { ...DateHeader, text: 'Creación', value: 'created' },
  { ...AppraisalStatusHeader, text: 'Estado compra', value: '@' },
  { ...AppraisalHeader, value: 'validator', text: 'Validador' },
  { ...PriceHeader, text: 'Precio autorizado', value: 'authorizePrice' },
  { ...PriceHeader, text: 'Precio de compra', value: 'price' },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 160 },
  {
    ...PdfResponsabilityHeader,
    text: 'Carta de responsabilidad',
    value: '@',
  },
  {
    ...PdfPurchaseHeader,
    text: 'Nota de compra',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
