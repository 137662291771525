import {
  ActionCellDefinition,
  CellType,
  LinkedCellDefinition,
  ListComponentCellDefinition,
  StatusCellDefinition,
} from '@/components/dataTables/cell'
import { Helpers } from '@/views/datatables/resources/helpers'
import { AppraisalStatusIcons } from '@/models/appraisal/interfaces'
import { Deal, Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const AppraisalHeader: ActionCellDefinition = {
  align: 'center',
  sortable: false,
  width: 85,
  filterable: false,
  type: CellType.action,
  options: {
    disabledActionMissingProperty: {
      key: 'appraiser',
    },
    path: 'purchase.appraisal.appraiser.person.name',
    trigger: Helpers.linkedModel,
    action: {
      always: true,
      color: 'primary',
      icon: 'mdi-whatsapp',
      only: true,
      messaging: true,
      trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'appraiser', process, system),
    },
    disabledMissingProperty: ['phone'],
  },
}

export const AppraiserHeader: LinkedCellDefinition = {
  align: 'center',
  width: 220,
  filterable: true,
  type: CellType.linked,
  options: {
    path: 'auto.name',
    trigger: ({ appraisal }: Deal) => Helpers.linkedModel(appraisal),
    allowCreate: true,
  },
}

export const AppraisalStatusHeader: StatusCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  type: CellType.status,
  options: {
    set: AppraisalStatusIcons,
  },
}

export const AppraisalPriceHeader: LinkedCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  type: CellType.linked,
  options: {
    path: '@',
    trigger: Helpers.addLinkedModel('price'),
  },
}

export const BestOfferHeader: ListComponentCellDefinition = {
  align: 'center',
  width: 200,
  filterable: true,
  type: CellType.cellList,
  options: {
    component: CellType.price,
    options: {
      bold: true,
      tooltip: true,
    },
  },
}
