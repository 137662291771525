import { ResourceHeaders } from '../index'
import {
  AppraisalStatusHeader,
  IconHeader,
  IdHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  { ...TextHeader, text: 'Fecha creado', value: 'created', width: 120 },
  { ...VehicleHeader, text: 'Vehiculo', value: 'stock.auto' },
  { ...VehicleStatusHeader, text: 'Estado Stock', value: 'stock.status' },
  { ...IconHeader, text: 'Documentación', value: 'metadata.isDocumentationStatusSupervisor' },
  { ...IconHeader, text: 'Carroceria', value: 'metadata.isBodyWorkStatusSupervisor' },
  { ...IconHeader, text: 'Ruedas', value: 'metadata.isWheelsStatusSupervisor' },
  { ...IconHeader, text: 'Interior', value: 'metadata.isInsideStatusSupervisor' },
  { ...IconHeader, text: 'Mecánica', value: 'metadata.isMechanicalStatusSupervisor' },
  { ...IconHeader, text: 'Aseo', value: 'metadata.isCleanStatusSupervisor' },
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
]

export default function (): ResourceHeaders {
  return headers
}
