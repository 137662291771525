import { CellType, StatusCellDefinition } from '@/components/dataTables/cell'
import { VehicleStatusIcons } from '@/models/vehicle/interfaces'

export const NegotiationStatusHeader: StatusCellDefinition = {
  align: 'center',
  filterable: true,
  width: 180,
  type: CellType.status,
  options: {
    set: VehicleStatusIcons,
  },
}

export const NegotiationQualificationHeader: StatusCellDefinition = {
  align: 'center',
  filterable: true,
  width: 200,
  type: CellType.status,
  options: {
    set: VehicleStatusIcons,
  },
}

export const LegalReportHeader: StatusCellDefinition = {
  align: 'center',
  filterable: true,
  width: 180,
  type: CellType.status,
  options: {
    set: VehicleStatusIcons,
  },
}
