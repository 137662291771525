import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  BusinessHeader,
  DateHeader,
  IdHeader,
  PersonHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      show: true,
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  { ...VehicleHeader, text: 'Vehiculo', value: 'saleOrder.deal.auto' },
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'saleOrder.deal.stock' },
  { ...BusinessHeader, text: 'Negocio ', value: 'saleOrder.deal.lead.pipeline' },
  {
    ...TextHeader,
    text: 'Cliente',
    value: 'saleOrder.deal.lead.client.name',
    width: 200,
  },
  {
    ...PersonHeader,
    text: 'Ejecutivo',
    value: 'saleOrder.deal.lead.executive',
    options: {
      action: {
        color: 'primary',
        always: true,
        icon: 'mdi-whatsapp',
        trigger: (item: Person) => Helpers.contact(item, 'Ejecutivo'),
      },
    },
  },
  { ...DateHeader, text: 'Fecha reserva', value: 'created' },
  { ...DateHeader, text: 'Fecha vencimiento', value: 'expired' },
  { ...AppraisalStatusHeader, text: 'Estado de reserva', value: '@' },
]

export default function (): ResourceHeaders {
  return headers
}
