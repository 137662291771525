import { ResourceHeaders } from '..'
import {
  AlertConsignmentHeader,
  AppraisalHeader,
  ChannelHeader,
  ConsignmentExpirationHeaders,
  ConsignmentHeaders,
  CreateLinkedRecordHeader,
  IdHeader,
  PdfConsignmentHeader,
  PdfConsignmentWithdrawalHeader,
  PdfResponsabilityHeader,
  PersonHeader,
  PriceHeader,
  StockButtonHeader,
  StockDailyHeader,
  StockLeadReceivedHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  {
    ...AlertConsignmentHeader, text: 'Alerta', value: '@',
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Retiro',
    value: 'buttonActivity',
    options: {
      action: {
        color: 'red',
        icon: 'mdi-file-document-arrow-right-outline',
        only: true,
        trigger: Helpers.linkedModel,
      },
    },
  },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'negotiation.client.name',
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto' },
  { ...ConsignmentExpirationHeaders, text: 'Vencimiento', value: '@' },
  { ...ConsignmentHeaders, text: 'Estado', value: '@' },
  { ...AppraisalHeader, value: 'validator', text: 'Validador' },
  { ...PriceHeader, text: 'Precio acordado', value: 'agreementPrice' },
  { ...PriceHeader, text: 'Utilidad proyectada', value: 'utility', width: 180 },
  { ...StockButtonHeader, text: 'Stock', value: '@', width: 100 },
  {
    ...StockLeadReceivedHeader,
    text: 'Leads recibidos',
    value: '@',
    width: 180,
  },
  {
    ...StockDailyHeader,
    text: 'Días',
    value: '@',
    width: 165,
  },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 160 },
  {
    ...PdfResponsabilityHeader,
    text: 'Carta de responsabilidad',
    value: '@',
  },

  {
    ...PdfConsignmentHeader,
    text: 'Contrato de consignación',
    value: '@',
  },
  {
    ...PdfConsignmentWithdrawalHeader,
    text: 'Retiro consignación',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
