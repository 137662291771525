import { ResourceHeaders } from '..'
import {
  CreateLinkedRecordHeader,
  DateHeader,
  FIHeader,
  FinancialHeader,
  IdHeader,
  PersonHeader,
  PriceHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { extendsObj } from '@/utils/data'
import { TableCellHeader } from '@/components/dataTables/cell'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'client.name',
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  {
    ...PersonHeader,
    text: 'Solicitante',
    value: '@',
    width: 200,
    options: {
      path: 'applicant.name',
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: 'saleOrder.deal',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'Stock' }, id: item.stock.id }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'saleOrder.deal.stock', width: 200 },
  { ...DateHeader, text: 'Fecha solicitud  ', value: 'created' },
  { ...TextHeader, text: 'Pie ', value: 'pie' },
  { ...PriceHeader, text: 'Monto solicitado', value: 'startingAmount', width: 160 },
  {
    ...CreateLinkedRecordHeader,
    text: 'Tareas',
    value: 'initial',
    options: {
      tooltip: 'Nueva Tarea',
      disabled: true,
      action: {
        color: 'primary',
        icon: 'mdi-calendar-month-outline',
        only: true,
        trigger: Helpers.addLinkedModel('activity'),
      },
    },
  },
  { ...FinancialHeader, text: 'Financiamiento', value: 'evaluation' },
  { ...TextHeader, text: 'Mejor comisión', value: 'bestCommission', width: 200 },
  { ...FIHeader, text: 'F & I', value: '@' },
]

export default function (): ResourceHeaders {
  return headers
}
