import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  BusinessHeader,
  ChannelHeader,
  DateHeader,
  IdHeader,
  MarketPricesHeader,
  TextHeader,
  VehicleHeader,
  WhatsappHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    width: 40,
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...BusinessHeader, text: 'Negocio', value: 'deal.lead.pipeline' },
  { ...TextHeader, text: 'Cliente', value: 'deal.lead.client.applicantWithRut', width: 180 },
  { ...ChannelHeader, text: 'Canal', value: 'deal.channel' },
  { ...VehicleHeader, text: 'Vehiculo', value: 'deal.auto' },
  { ...DateHeader, text: 'Fecha creación', value: 'created' },
  { ...TextHeader, text: 'Pago realizado', value: 'payments', width: 200 },
  { ...AppraisalStatusHeader, text: 'Estado de venta', value: '@' },
  {
    ...WhatsappHeader,
    text: 'Ejecutivo',
    value: 'deal.lead.executive',
    width: 280,
    options: {
      action: {
        color: 'blue',
        always: true,
        icon: 'mdi-whatsapp',
        trigger: (item: Person) => Helpers.contact(item, 'Validator'),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  {
    ...MarketPricesHeader,
    text: 'Precio y descuento',
    value: 'deal.stock.priceDiscount',
    width: 240,
  },
  { ...TextHeader, text: 'Precio sin descuento', value: 'priceWithOutDiscount.value', width: 200 },
  { ...TextHeader, text: 'Descuento otorgado', value: 'discountApply.value', width: 200 },
  { ...TextHeader, text: 'Precio venta', value: 'priceSell.value', width: 200 },
  { ...TextHeader, text: 'Costo traspaso', value: 'transferAmount.value', width: 200 },
  { ...TextHeader, text: 'Saldo a favor', value: 'marginPositive', width: 200 },
]

export default function (): ResourceHeaders {
  return headers
}
