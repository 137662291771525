import VueRouter from 'vue-router'
import { Store } from 'vuex'
import { State } from '@/store/state'

import { ResourceHeaders, resources } from './roles'
import { Helpers } from './helpers'
import { Person } from '@/models'
import { Lead } from '@/entities/crm'

export function loadResources (router: VueRouter, store: Store<State>) {
  const helpers = new Helpers(router, store)
  const _headers: Record<string, ResourceHeaders> = {}

  return async function getHeaders (slug: string): Promise<ResourceHeaders> {
    let headers = _headers[slug]
    if (!headers) {
      const resource = resources[slug]
      if (!resource) return []

      headers = _headers[slug] = resource(helpers)
    }

    return headers
  }
}

type ResourceModel = Person | Lead
export const models: Record<string, new (...args: any) => ResourceModel> = {
  person: Person,
  lead: Lead,
}
