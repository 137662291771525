import { RoleResources } from '..'

import expenses from './expenses'
import income from './income'
import accountBalance from './accountBalance'

export const treasurer: RoleResources = {
  treasurer_expenses: expenses,
  treasurer_income: income,
  treasurer_account_balance: accountBalance,
}
