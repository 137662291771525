import { ResourceHeaders } from '..'
import {
  IdHeader,
  VehicleHeader,
  InspectionProgressHeader, InspectionQualificationHeader,
  InspectionStatusHeader, WhatsappHeader, AddressHeader, DateHeader, InspectionSummary,
} from '../../headers'
import { Helpers } from '../../helpers'
import { Person } from '@/entities/persons'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  { ...VehicleHeader, text: 'Vehiculo', value: 'appraisal.deal.auto' },
  { ...AddressHeader, text: 'Ubicación', value: 'address' },
  { ...DateHeader, text: 'Fecha Agendada', value: 'scheduled' },
  { ...DateHeader, text: 'Fecha Inicio', value: 'dateFormatted' },
  { ...InspectionStatusHeader, text: 'Estado', value: 'inspectionStatus' },
  { ...InspectionProgressHeader, text: 'Progreso', value: '@' },
  {
    ...WhatsappHeader,
    text: 'Supervisor',
    value: 'supervisor',
    options: {
      action: {
        color: 'primary',
        always: true,
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Person) => Helpers.contact(item, 'Supervisor'),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  {
    ...InspectionSummary,
    text: 'Reporte',
    value: '@',
    options: {
      tooltip: 'Reporte',
      disabled: true,
      action: {
        color: 'purple',
        icon: 'mdi-clipboard-text-search-outline',
        only: true,
      },
    },
  },
  { ...InspectionQualificationHeader, text: 'Calificación', value: 'qualification' },
]

export default function (): ResourceHeaders {
  return headers
}
