import { ResourceHeaders } from '..'

import {
  AlertsHeader,
  AppraisalHeader,
  AppraisalStatusHeader,
  BestOfferHeader,
  BusinessHeader,
  IdHeader,
  IndicatorHeader,
  PriceHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    width: 100,
    options: {
      show: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...AlertsHeader, text: 'Alerta', value: 'appraisalAlert', sortable: false, width: 30 },
  { ...VehicleHeader, text: 'Vehiculo', value: 'deal.auto' },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  { ...AppraisalHeader, value: 'executive', text: 'Ejecutivo' },
  { ...AppraisalStatusHeader, text: 'Estado tasación', value: 'appraisalStatus' },
  { ...IndicatorHeader, text: 'Indicadores', value: 'indicator' },
  /* { ...MarketPricesHeader, text: 'Precios de mercado', value: 'market.overview' }, */
  { ...BestOfferHeader, text: 'Mejor oferta', value: 'bestOffer', sortable: false },
  { ...PriceHeader, text: 'Diferencia para acuerdo', value: 'agreement', width: 160 },
]

export default function (): ResourceHeaders {
  return headers
}
