import { ResourceHeaders } from '..'
import { AppraisalStatusHeader, BackupPaymentHeader, IdHeader, TextHeader, VehicleHeader } from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'
import { CellType } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      show: true,
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'deal.auto' },
  { ...TextHeader, text: 'Tipo de pago', value: 'type.description', width: 120 },
  { ...TextHeader, text: 'Vencimiento', value: 'expirationLocalDate' },
  { ...TextHeader, text: 'Monto', value: 'amountFixed', width: 60 },
  { ...TextHeader, text: 'Pagador', value: 'payer.shortName', width: 120 }, // id pager
  { ...TextHeader, text: 'Cuenta de abono', value: 'financialAccount.bankAccount', width: 200 }, // payment count
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
  {
    align: 'center',
    filterable: true,
    type: CellType.linked,
    text: 'Ejecutivo',
    value: 'deal.lead.executive',
    sortable: false,
    width: 140,
    options: {
      path: 'executive.person.name',
      action: {
        color: 'green',
        always: true,
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Person) => Helpers.contact(item, 'executive'),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...TextHeader, text: 'Responsable', value: 'responsible.person.shortName', width: 120 },
  {
    ...BackupPaymentHeader,
    text: 'Respaldo',
    value: '@',
    width: 60,
    sortable: false,
    options: {
      name: 'payment_receipt',
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
