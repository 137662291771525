import { CellType, LinkedCellDefinition } from '@/components/dataTables/cell'
import { Helpers } from '../helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const PersonHeader: LinkedCellDefinition = {
  align: 'center',
  width: 250,
  filterable: true,
  type: CellType.linked,
  options: {
    disabledActionMissingProperty: {
      key: 'client',
    },
    path: 'client.name',
    trigger: Helpers.linkedModel,
    action: {
      always: true,
      color: 'green',
      icon: 'mdi-whatsapp',
      messaging: true,
      trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
    },
    disabledMissingProperty: ['phone'],
  },
}
