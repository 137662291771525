import { ResourceHeaders } from '..'
import { AppraisalStatusHeader, IdHeader, VehicleHeader, VehicleStatusHeader } from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { CellType } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...VehicleHeader, text: 'Stock', value: 'auto' },
  { ...VehicleStatusHeader, text: 'Estado Stock', value: 'stock' },
  {
    align: 'center',
    width: 100,
    filterable: true,
    type: CellType.linked,
    text: 'Ejecutivo',
    value: 'executive',
    options: {
      action: {
        color: 'blue',
        always: true,
        icon: 'mdi-whatsapp',
        only: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...AppraisalStatusHeader, text: 'Estado traspaso', value: '@' },
]

export default function (): ResourceHeaders {
  return headers
}
