import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  IconHeader,
  IdHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  { ...TextHeader, text: 'Fecha creado', value: 'created', width: 120 },
  { ...VehicleHeader, text: 'Vehiculo', value: 'stock.auto' },
  { ...VehicleStatusHeader, text: 'Estado Stock', value: 'stock.status' },
  { ...TextHeader, text: 'Llave', value: 'stock.keyIdentifier.keyId', width: 120 },
  { ...IconHeader, text: 'Documentación', value: 'metadata.isDocumentationStatus' },
  { ...IconHeader, text: 'Carroceria', value: 'metadata.isBodyWorkStatus' },
  { ...IconHeader, text: 'Ruedas', value: 'metadata.isWheelsStatus' },
  { ...IconHeader, text: 'Interior', value: 'metadata.isInsideStatus' },
  { ...IconHeader, text: 'Mecánica', value: 'metadata.isMechanicalStatus' },
  { ...IconHeader, text: 'Aseo', value: 'metadata.isCleanStatus' },
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
]

export default function (): ResourceHeaders {
  return headers
}
