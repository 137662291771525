import { ResourceHeaders } from '..'

import {
  AlertsHeader,
  AppraisalHeader,
  AppraisalStatusHeader,
  AppraiserHeader,
  BusinessHeader,
  ChannelHeader,
  CreateLinkedRecordHeader,
  DateHeader,
  FIHeader,
  FinancialHeader,
  IdHeader,
  PersonHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { extendsObj } from '@/utils/vuetify/helpers'
import { TableCellHeader } from '@/components/dataTables/cell'
import { Deal } from '@/entities/crm'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
  },
  { ...AlertsHeader, text: 'Alertas ', value: 'alerts' },
  { ...DateHeader, text: 'Creación', value: 'created', sortable: false },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  { ...PersonHeader, text: 'Cliente', value: '@' },
  { ...ChannelHeader, text: 'Canal', value: 'channel' },
  { ...DateHeader, text: 'Vencimiento', value: 'lastLeadActivityOutDate', sortable: true },
  {
    ...CreateLinkedRecordHeader,
    text: 'Tareas',
    value: 'buttonActivity',
    options: {
      action: {
        color: 'primary',
        icon: 'mdi-calendar-month-outline',
        only: true,
        trigger: Helpers.linkedModel,
      },
    },
  },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: 'sale',
    options: {
      path: 'auto.name',
      trigger: ({ stock }: Deal) => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...stock }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado Stock', value: 'saleStatus' },
  { ...AppraiserHeader, text: 'Tasación', value: 'purchase' },
  { ...AppraisalStatusHeader, text: 'Estado tasación', value: 'purchase.appraisal' },
  {
    ...CreateLinkedRecordHeader,
    text: 'Agendar',
    value: 'buttonInspection',
    options: {
      tooltip: 'Inspección',
      action: {
        color: 'purple',
        icon: 'mdi-car-search',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
  { ...AppraisalHeader, text: 'Tasador', value: '@' },
  { ...FinancialHeader, text: 'Financiamiento', value: 'sale.order.financing.evaluation' },
  { ...FIHeader, text: 'F & I', value: '@' },
  {
    ...CreateLinkedRecordHeader,
    text: 'Reservar',
    value: 'buttonReserve',
    options: {
      tooltip: 'Reservar',
      action: {
        color: 'blue',
        icon: 'mdi-car-outline',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Venta',
    value: 'buttonSell',
    options: {
      tooltip: 'Venta',
      action: {
        color: 'green',
        icon: 'mdi-cart-arrow-up',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
