import { RoleResources } from '..'
import inspection from './inspection'
import enablement from './enablement'
import stock from './stock'

export const inspector: RoleResources = {
  inspector_inspections: inspection,
  inspector_enablement: enablement,
  inspector_stock: stock,
}
