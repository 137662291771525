import { ResourceHeaders } from '..'
import {
  DateHeader, FIHeader, FinancialHeader, IconHeader,
  IdHeader, PersonHeader,
  PriceHeader,
  VehicleHeader,
  VehicleStatusHeader, WhatsappHeader,
} from '@/views/datatables/resources/headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Person } from '@/entities/persons'

export const headers: ResourceHeaders = [
  { ...IdHeader, text: 'Nº', value: '@' },
  { ...IconHeader, text: 'Estado negocio', value: 'financing.saleOrder.deal.status', sortable: false, width: 130 },
  { ...VehicleHeader, text: 'Vehiculo', value: 'financing.saleOrder.deal.auto' },
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'financing.saleOrder.deal.auto.autoStatus' },
  { ...DateHeader, text: 'Fecha de solicitud  ', value: 'created' },
  { ...PriceHeader, text: 'Monto', value: 'financing.startingAmount', width: 160 },
  { ...PersonHeader, text: 'Cliente', value: 'financing.client' },
  {
    ...PersonHeader,
    text: 'Solicitante',
    value: 'financing.applicant',
    options: {
      path: 'name',
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'purple',
        icon: 'mdi-whatsapp',
        trigger: (item: Person) => Helpers.contact(item, 'Cliente', true),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...FinancialHeader, text: 'Estado', value: '@' },
  { ...WhatsappHeader, text: 'Ejecutivo', value: 'financing.executive' },
  { ...FIHeader, text: 'F & I', value: 'financing.responsible' },
]

export default function (): ResourceHeaders {
  return headers
}
