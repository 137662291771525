import { ResourceHeaders } from '..'
import {
  AlertReserveSellCellHeader,
  AppraisalStatusHeader,
  BusinessHeader,
  CreateLinkedRecordHeader,
  DateHeader,
  IdHeader,
  PdfReserveCellHeader,
  PersonHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { extendsObj } from '@/utils/data'
import { TableCellHeader } from '@/components/dataTables/cell'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 80,
  },
  {
    ...AlertReserveSellCellHeader, text: 'Alerta', value: '@',
  },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: 'saleOrder.deal',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'Stock' }, id: item.stock.id }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'saleOrder.deal.stock' },
  { ...BusinessHeader, text: 'Negocio ', value: 'saleOrder.deal.lead.pipeline' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: 'saleOrder.deal.lead.client',
    options: {
      trigger: Helpers.linkedModel,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['phone'],
    },
  },
  { ...TextHeader, text: 'Abono', value: 'payments' },
  { ...DateHeader, text: 'Fecha reserva', value: 'created' },
  { ...DateHeader, text: 'Fecha vencimiento', value: 'expired' },
  { ...AppraisalStatusHeader, text: 'Estado de reserva', value: '@' },
  {
    ...PdfReserveCellHeader,
    text: 'Reserva sin firmar',
    value: '@',
    width: 150,
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Vender',
    value: 'buttonSell',
    options: {
      tooltip: 'Abrir formulario de venta',
      action: {
        color: 'green',
        icon: 'mdi-cart-arrow-up',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
