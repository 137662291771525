import {
  CellType,
  LinkedCellDefinition,
  ProgressCellDefinition, RatingCellHeader,
  StatusCellDefinition,
} from '@/components/dataTables/cell'
import { Helpers } from '@/views/datatables/resources/helpers'
import { VehicleStatusIcons } from '@/models/vehicle/interfaces'

export const InspectionPriceHeader: LinkedCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  type: CellType.linked,
  options: {
    path: '@',
    trigger: Helpers.addLinkedModel('tasks'),
  },
}

export const InspectionDateHeader: LinkedCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  type: CellType.linked,
  options: {
    path: '@',
    trigger: Helpers.addLinkedModel('date'),
  },
}

export const InspectionStatusHeader: StatusCellDefinition = {
  align: 'center',
  filterable: true,
  width: 140,
  type: CellType.status,
  options: {
    set: VehicleStatusIcons,
  },
}
export const InspectionProgressHeader: ProgressCellDefinition = {
  align: 'center',
  filterable: true,
  sortable: false,
  width: 180,
  type: CellType.progress,
}

export const InspectionQualificationHeader: RatingCellHeader = {
  text: '',
  value: '',
  align: 'center',
  filterable: true,
  width: 180,
  type: CellType.rating,
  sortable: false,
}

export const InspectionCostHeader: LinkedCellDefinition = {
  align: 'center',
  width: 180,
  filterable: true,
  type: CellType.linked,
  options: {
    path: '@',
    trigger: Helpers.addLinkedModel('price'),
  },
}
